
    ::-webkit-scrollbar {
        width: 5px; /* ширина для вертикального скролла */
        height: 8px; /* высота для горизонтального скролла */
    }

    ::-webkit-scrollbar-thumb {
        width: 12px;
        background-color: #000000;
        border-radius: 9em;
        box-shadow: inset 1px 1px 10px #7d24bb;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #9900ff;
    }

    body {
        background: linear-gradient(#161616, rgba(22, 22, 22, 0.9)), url("/src/img/bg.jpg");
        background-position: center center;
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #212121;
        z-index: -1;
        overflow-x: hidden;
    }

    .nav {
        background-color: #111111bf;
    }

    .nav-it {
        background-color: #161616;
    }

    .nav-it-hov {
        background-color: #161616;
    }

    .bgprof {
        background-image: url("/src/img/prof.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .bggrd {
        background-image: linear-gradient(180deg, rgba(162, 0, 255, 0) 0%, rgba(162, 0, 255, 0.7) 100%);
        z-index: 11;
    }

    .menus {
        transform: translateX(-120%);
        transition: 0.6s;
        width: 100%;
        z-index: 98;
        position: fixed;
        background-color: #252525
    }

    .menus.active {
        transform: translateX(0);
        transition: 0.6s;
        margin-top: 0;
    }

    .cont {
        transform: translateX(0%);
        transition: 0.6s;
    }

    .cont.active {
        transform: translateX(-120%);
        transition: 0.6s;
    }

    .navv {
        z-index: 99;
    }

    .mhmenu {
        min-height: 650px;
    }

    .weaponsic {
        max-height: 2.2vw;
        vertical-align: middle;
        horiz-align: middle;
        filter: invert(var(--svg));
    }

    .grad {
        background-image: linear-gradient(329deg, #531751, #ab3444, #954616);
    }